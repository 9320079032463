.App {
  text-align: center;
  background-color: rgb(235, 238, 241);
  width: 100%;
}

.AppMobile {
  text-align: center;
  background-color: white;
  width: fit-content;
}

body{
  font-family:Verdana, Geneva, sans-serif;
  font-size:18px;
  background-color:#CCC;
}

.my-badge{
  color: white;
}

.cards{
  margin-right: 200%;
}
