
.navbar-desktop{
    background: rgb(0, 138, 198 );
    margin-bottom: 25px;
}

.navbar-mobile{
    background: rgb(0, 138, 198 );
    margin-bottom: 25px;
    width: 100%;
}

.menu-mobile {
    margin-right: 50%;
}

.logo {
    width: 70%;    
}