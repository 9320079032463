h8 {
    margin-top: 25px;
}

h7 {
    margin-top: 15%;
    width: 90%;
}

Button {
    margin: 15px;
}