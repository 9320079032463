
.quem-somos-container{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  
  .quem-somos-texto{
    width: 90%;
    text-align: center;
    border-radius: 1%;
    border: 1px solid #CCC;
    margin: 2%;
    padding: 2%;
    background: white;
  }
  