.float{
    z-index: 15;
	position:fixed;
	width:90px;
	height:90px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.float-mobile {
	left: 70%;
}

.icone{
    margin-top: 18%;
}
