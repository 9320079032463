.slide {
    padding: 0 5% 5% 5%;
}

.slide-container {
  display: flex;
  flex-direction: column;
}

Button {
  margin-top: 10px;
  margin-left: 20%;
  margin-right: 20%;
}

.card-image-column {
  cursor: pointer;
  width: 50%;
  height: 50%;
  margin-left: 25%;
}

.card-image-row {
  cursor: pointer;
  width: 50%;
  height: 50%;
  margin-left: 5%;
}


.slide-item-column {
  flex-direction: column;
}

.slide-item-row {
  flex-direction: row;
}