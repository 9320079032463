.footer{
    margin-top: 15px;
    padding: 1%;
    display: flex;
    color: white;
    background: rgb(0, 138, 198 );
}

.footer-item{
    flex: 1;
  }
  