.container1 {
    display: flex;
    flex-direction: row;
}

.container2 {
    position: relative;
    text-align: center;
    color: white;
}

.text-container  {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.text-container > h4 {
    word-wrap: break-word;
    text-align: left;
    margin-top: 80px;
}

.right {
    display: flex;
    flex-direction: column;
}

.bottom-left {
    position: absolute;
    bottom: 300px;
    left: 16px;
    text-align: left;
}
  
.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}
  
.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}
  
.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}
  
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

img {
    /* width: 5000px; */
}