
.card-header{
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.card-title{
  height: 50px;
}

.card-texto{
  height: auto;
}

.botao-saiba-mais{
  position: absolute;
  left: 200px;
  bottom: 5px;
}